/* Modern Font Import */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&family=Outfit:wght@100..900&display=swap');

/* Reset and Global Styles */
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  background-color: #0F172A;
  color: #E2E8F0;
}

.App {
  margin: 0 auto;
  overflow-x: hidden;
  background: linear-gradient(135deg, #0F172A 0%, #1E293B 100%);
  min-height: 100vh;
}

/* Progress Bar with modern gradient */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background: linear-gradient(90deg, #22D3EE 0%, #818CF8 50%, #F472B6 100%);
  transform-origin: 0%;
  width: 100%;
  transform: scaleX(0);
  z-index: 100;
}

/* Header with artistic flair */
.header {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(180deg, rgba(15, 23, 42, 0.9) 0%, rgba(30, 41, 59, 0.8) 100%);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10000;
}

.header-title {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(90deg, #22D3EE 0%, #818CF8 50%, #F472B6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
}

.header-subtitle {
  font-size: 1.1rem;
  color: #94A3B8;
  font-weight: 400;
}

/* Modern Search Bar */
.search-bar-container {
  position: sticky;
  top: 20px;
  margin: 20px auto;
  max-width: 600px;
  padding: 0 20px;
  z-index: 999;
}

.search-input {
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #E2E8F0;
  font-size: 1rem;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(130, 140, 248, 0.5);
  box-shadow: 0 0 0 4px rgba(130, 140, 248, 0.1);
}

/* Event List Styling */
.event-list {
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
}

.date-section {
  margin-bottom: 60px;
}

.date-heading {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  color: #E2E8F0;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(130, 140, 248, 0.3);
}

/* Event Cards Grid */
.events-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 0;
  margin: 0;
}

.event-card {
  background: rgba(30, 41, 59, 0.5);
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: calc(100% - 48px); /* Subtract total padding to prevent overflow */
  display: flex;
  flex-direction: column;
  text-decoration: none; /* Remove underline */
}

.event-card:hover {
  transform: translateY(-4px);
  background: rgba(30, 41, 59, 0.8);
  border-color: rgba(130, 140, 248, 0.3);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.event-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #F8FAFC;
  margin: 0 0 12px 0;
  line-height: 1.4;
  text-decoration: none; /* Remove underline */
}

.event-location {
  font-size: 0.9rem;
  color: #94A3B8;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none; /* Remove underline */
}

/* Language Switcher */
.language-switcher {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1010;
}

.lang-button {
  background: rgba(255, 255, 255, 0.05);
  color: #E2E8F0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  z-index: 2000;
}

.lang-button.active {
  background: rgba(130, 140, 248, 0.2);
  border-color: rgba(130, 140, 248, 0.5);
  color: #F8FAFC;
  z-index: 2000;
}

/* Side Progress Indicator */
.side-progress-bar {
  position: fixed;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1000;
}

.date-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1000;
}

.date-indicator.active {
  background-color: #818CF8;
  box-shadow: 0 0 20px rgba(130, 140, 248, 0.4);
  z-index: 1000;
}

.side-date-indicator {
  position: fixed;
  top: 24px; /* Adjusted to prevent overlap with language switcher */
  right: 24px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 0.9rem;
  color: #F8FAFC;
  background: rgba(30, 41, 59, 0.9);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.event-submission-box {
  max-width: 600px;
  padding: 10px;
  background: rgba(30, 41, 59, 0.5);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin: auto;
  margin-bottom: 50px;
}

.event-submission-box h3 {
  font-family: 'Space Grotesk', sans-serif;
  color: #F8FAFC;
  text-align: center;
}

.event-input {
  width: calc(100% - 32px);
  padding: 15px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #E2E8F0;
  margin-bottom: 12px;
}

.submit-button {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: linear-gradient(90deg, #22D3EE 0%, #818CF8 100%);
  border: none;
  color: #F8FAFC;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.category-filter {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
  padding: 16px 16px;
  margin: 0 auto;
}

.filter-tag {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid rgba(148, 163, 184, 0.2);
  background: rgba(148, 163, 184, 0.1);
  color: rgb(148, 163, 184);
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-tag:hover {
  background: rgba(148, 163, 184, 0.2);
}

.filter-tag.active {
  background: rgba(99, 102, 241, 0.1);
  border-color: rgba(99, 102, 241, 0.3);
  color: rgb(99, 102, 241);
}


/* Responsive Design */
@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
  }
  
  .events-container {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
  }
  
  .side-progress-bar {
    right: 12px;
  }
  
  .side-date-indicator {
    font-size: 0.8rem;
    right: 12px;
    top: 58px; /* Maintain consistent spacing on mobile */
  }
  
  .event-card {
    padding: 20px;
    height: calc(100% - 40px); /* Adjust for smaller padding on mobile */
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 2rem;
    margin-top: 40px;
  }
  
  .event-list {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .events-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
    width: 100%;
  }
  
  .event-card {
    padding: 12px;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }
  
  .event-title {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }
  
  .event-location {
    font-size: 0.8rem;
  }
}